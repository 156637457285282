import React from "react"
import { graphql } from "gatsby"

import { GiftCard as Page } from "@components/GiftCard/GiftCard"

export const query = graphql`
  query {
    page: sanityGiftcardPage {
      title: _rawTitle(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      image: _rawImage(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
