import React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { withGiftCard } from "./withGiftCard"
import { Image } from "../Image/Image"
import { H4, P } from "../Styled/Text"
import { FormError } from "../Styled/Form"
import { StyledContainer, Card, Details, Bg, Row, Column, Button, Amount, CardImage, Heading, Subheading, ActionButton } from "./GiftCardStyles"

interface Props {
  applied: boolean
  code: string
  content: any
  copied: boolean
  date: any
  error: any
  formatCode: any
  formatMoney: any
  giftCardApplicable: boolean
  giftCard: any
  handleApply: any
  handleCart: any
  handleCode: any
  handleCopied: any
  handleShop: any
  hasLineItems: boolean
  loading: boolean
  locales: any
  outputCode: string
}

export const GiftCard = withGiftCard(
  ({
    applied,
    code,
    content,
    copied,
    date,
    error,
    formatCode,
    formatMoney,
    giftCard,
    giftCardApplicable,
    handleApply,
    handleCart,
    handleCode,
    handleCopied,
    handleShop,
    hasLineItems,
    loading,
    locales,
    outputCode,
  }: Props) => (
    <div>
      {!giftCard?.code ? (
        <StyledContainer>
          <Heading>{locales.empty}</Heading>
          <P>{locales.emptyDescription}</P>
          <ActionButton onClick={handleShop}>{locales.shop}</ActionButton>
        </StyledContainer>
      ) : (
        <StyledContainer>
          <Heading>{content.title}</Heading>

          <Card>
            <CardImage>
              <Image cover src={content?.image?.asset?.url} />
              <Bg />
              <Amount>{formatMoney(giftCard?.balance?.remaining)}</Amount>
            </CardImage>

            <Details>
              <Row spacing>
                <Column fill>
                  <H4>{formatCode(outputCode)}</H4>
                </Column>
                <Column right>
                  {code ? (
                    <CopyToClipboard text={giftCard?.code} onCopy={handleCopied}>
                      <Button>{copied ? locales.copied : locales.copy}</Button>
                    </CopyToClipboard>
                  ) : (
                    <Button onClick={handleCode}>{locales.show}</Button>
                  )}
                </Column>
              </Row>

              <Row>
                <Column>
                  <P>{giftCard?.customer?.first_name ? `${locales.to} ${giftCard?.customer.first_name} ${giftCard?.customer.last_name}` : null}</P>
                </Column>
                <Column right>
                  <P>
                    {!giftCard?.enabled
                      ? locales.disabled
                      : giftCard?.expired
                      ? `${locales.expired} ${giftCard?.expires_on}`
                      : giftCard?.expires_on
                      ? `${locales.expires} ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
                      : null}
                  </P>
                </Column>
              </Row>
            </Details>
          </Card>

          <Subheading>{applied ? locales.descriptionApplied : locales.descriptionApply}</Subheading>

          {applied && hasLineItems ? (
            <ActionButton onClick={handleCart}>{locales.viewButton}</ActionButton>
          ) : giftCardApplicable && !applied ? (
            <ActionButton disabled={loading} onClick={handleApply}>
              {locales.apply}
            </ActionButton>
          ) : (
            <ActionButton onClick={handleShop}>{locales.shop}</ActionButton>
          )}

          {error && <FormError>{error}</FormError>}
        </StyledContainer>
      )}
    </div>
  )
)
