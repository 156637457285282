import styled from "@emotion/styled"
import tw from "twin.macro"

import { H3, H4, P } from "../Styled/Text"
import { TextButton, PrimaryButton } from "../Styled/Button"
import { Container } from "../Styled/Container"

export const StyledContainer = tw(Container)`flex flex-col items-center justify-center max-w-50 mt-12 mb-12`
export const Card = tw.div`relative w-full`
export const CardImage = tw.div`relative w-full h-20 bg-grey-darkest rounded-t-1-2 overflow-hidden`
export const Bg = tw.div`absolute inset-0 bg-dark opacity-30`
export const Details = tw.div`px-1 md:px-1-6 py-1-6 bg-grey-lightest text-dark rounded-b-1-2`
export const Button = tw(TextButton)`uppercase text-xs`
export const Amount = tw(H3)`absolute top-0 left-0 pt-1-6 pl-1-6 text-light italic`
export const Row = styled.div`
  ${tw`flex flex-col md:flex-row items-center md:items-start md:justify-between text-center md:text-left`}
  ${props => (props.spacing ? tw`md:mb-1-6` : ``)}
`
export const Column = styled.div`
  ${tw`mb-1-6 md:mb-0`}
  ${props => (props.right ? tw`text-right` : tw`text-left`)}
  ${props => (props.fill ? tw`flex-1` : ``)}
`
export const Heading = tw(H4)`text-lmd font-sans font-light leading-relaxed uppercase mb-8`
export const Subheading = tw(P)`mt-3-2 mx-auto md:px-3-2 text-grey-darkest text-center`
export const ActionButton = tw(PrimaryButton)`mt-3-2`
